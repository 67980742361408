@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'OPTIGoudy Agency';
  src: url('../public/OPTIGoudy-MediumAgency.otf') format('woff2'),
}

.tracking-tighter {
  letter-spacing: 0em !important;
  margin-right: 0.5em;
}
